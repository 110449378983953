import React from "react"
import styled from "styled-components"

// ========================================= //
// Styles
// ========================================= //


// ========================================= //
// Main Component
// ========================================= //

const NotFound = () => {
  // ========================================= //
  // State
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>404 or something</>
  )
}

export default NotFound
